import * as VueRouter from 'vue-router'

const Test = () => import('@/pages/Test');

const Empty = () => import('@/pages/Empty');
const PhoneNumber = () => import('@/pages/PhoneNumber');
const ChooseSubscription = () => import('@/pages/ChooseSubscription');
const Crossroads = () => import('@/pages/Crossroads');

const GetCode = () => import('@/pages/Promocode/GetCode');
const GetCodeShort = () => import('@/pages/Promocode/GetCodeShort');
const GetCodeShortV2 = () => import('@/pages/Promocode/GetCodeShortV2');
const EnterCode = () => import('@/pages/Promocode/EnterCode');

const ShowSubscription = () => import('@/pages/ShowSubscription');

const ChooseSectionLayout = () => import('@/layouts/ChooseSection');
const ChooseSectionOsago = () => import('@/pages/ChooseSection/Osago');
const ChooseSectionInsurance = () => import('@/pages/ChooseSection/Insurance');
const ChooseSectionFinance = () => import('@/pages/ChooseSection/Finance');

const WelcomeLayout = () => import('@/layouts/Welcome');
const WelcomeFines = () => import('@/pages/Welcome/Fines');
const WelcomeTaxes = () => import('@/pages/Welcome/Taxes');
const WelcomeInsurance = () => import('@/pages/Welcome/Insurance');
const WelcomeSupport = () => import('@/pages/Welcome/Support');
const WelcomeAll = () => import('@/pages/Welcome/AllIn');

const WelcomeSolo = () => import('@/pages/WelcomeSolo');

const LoadingFines = () => import('@/pages/LoadingFines');

const GoAutoOnboardingMoney = () => import ('@/pages/GoAuto/Onboarding/Money');
const GoAutoOnboardingGas = () => import ('@/pages/GoAuto/Onboarding/Gas');
const GoAutoOnboardingSpares = () => import ('@/pages/GoAuto/Onboarding/Spares');
const GoAutoOnboardingFines = () => import ('@/pages/GoAuto/Onboarding/Fines');
const GoAutoOnboardingOsago = () => import ('@/pages/GoAuto/Onboarding/Osago');
const GoAutoPaymentTBank = () => import('@/pages/GoAuto/Payment/Cameramap/Tbank');


const GoAutoFuelWelcome = () => import('@/pages/GoAuto/Benzuber/Fuel');
const GoAutoFuelCard = () => import('@/pages/GoAuto/Benzuber/Card');

const OsagoProlongation = () => import('@/pages/TildaPromo/OsagoProlongation');
const OsagoProlongationIos = () => import('@/pages/TildaPromo/OsagoProlongationIos');

const Pcodes = () => import('@/pages/Pcodes');

const router = VueRouter.createRouter({
  mode: 'history',
  history: VueRouter.createWebHistory(),
  base: '/',
  routes: [
    {path: '/', component: Empty, name: 'start'},
    {path: '/test', component: Test, name: 'testPage'},

    {path: '/phone-number', component: PhoneNumber, name: 'phoneNumber'},
    {path: '/choose-subscription', component: ChooseSubscription, name: 'chooseSubscription'},
    {path: '/crossroads', component: Crossroads, name: 'crossroads'},

    {path: '/get-code', component: GetCode, name: 'getCode'},
    {path: '/get-code-short', component: GetCodeShort, name: 'getCodeShort'},
    {path: '/get-code-short-v2', component: GetCodeShortV2, name: 'getCodeShortV2'},
    {path: '/enter-code', component: EnterCode, name: 'enterCode'},

    {path: '/show-subscription', component: ShowSubscription, name: 'showSubscription'},

    {
      path: '/choose-section',
      component: ChooseSectionLayout,
      children: [
        {path: 'osago', component: ChooseSectionOsago, name: 'choose-section-osago'},
        {path: 'finance', component: ChooseSectionFinance, name: 'choose-section-finance'},
        {path: 'insurance', component: ChooseSectionInsurance, name: 'choose-section-insurance'},
      ]
    },
    {
      path: '/welcome',
      component: WelcomeLayout,
      children: [
        {path: 'fines', component: WelcomeFines, name: 'welcome-screen-fines'},
        {path: 'taxes', component: WelcomeTaxes, name: 'welcome-screen-taxes'},
        {path: 'insurance', component: WelcomeInsurance, name: 'welcome-screen-insurance'},
        {path: 'support', component: WelcomeSupport, name: 'welcome-screen-support'},
        {path: 'all-in', component: WelcomeAll, name: 'welcome-screen-all'},
      ]
    },
    {path: '/welcome-solo', component: WelcomeSolo, name: 'welcome-screen-solo'},
    {path: '/loading-fines', component: LoadingFines, name: 'loading-fines'},
    {
      path: '/goauto',
      component: WelcomeLayout,
      children: [
        {path: 'money', component: GoAutoOnboardingMoney, name: 'goauto-onboarding-money'},
        {path: 'gas', component: GoAutoOnboardingGas, name: 'goauto-onboarding-gas'},
        {path: 'spares', component: GoAutoOnboardingSpares, name: 'goauto-onboarding-spares'},
        {path: 'fines', component: GoAutoOnboardingFines, name: 'goauto-onboarding-fines'},
        {path: 'osago', component: GoAutoOnboardingOsago, name: 'goauto-onboarding-osago'},
      ]
    },
    {
      path: '/goauto/fuel',
      component: WelcomeLayout,
      children: [
        {path: 'card', component: GoAutoFuelCard, name: 'goauto-fuel-card'},
      ]
    },
    {path: '/goauto/payment/cameramap/tbank/:tariff/:countDays', component: GoAutoPaymentTBank, name: 'goauto-payment-tbank', meta: { title: 'Оформление заказа'} },
    {path: '/goauto/fuel/welcome', component: GoAutoFuelWelcome, name: 'goauto-fuel-welcome'},
    {path: '/osago-prolongation', component: OsagoProlongation, name: 'osago-prolongation'},
    {path: '/osago-prolongation-ios', component: OsagoProlongationIos, name: 'osago-prolongation-ios'},

    {path: '/pcodes', component: Pcodes, name: 'pcodes'},

    { path: '/tangerine-patrol', component: () => import('@/pages/TangerinePatrol/TangerinePatrol'), name: 'tangerinePatrol' },

    { path: '/calendar/permission', component: () => import('@/pages/Calendar/Permission'), name: 'calendarPermission' },

    { path: '/add-services-contacts', component: () => import('@/pages/AddServicesContacts'), name: 'addServicesContacts' },

    { path: '/confirm-sts', component: () => import('@/pages/ConfirmSts'), name: 'confirmSts' },

    { path: '/is-it-your-inn', component: () => import('@/pages/IsItYourInn'), name: 'isItYourInn' },

    { path: '/is-it-your-inn-v2', component: () => import('@/pages/IsItYourInnV2'), name: 'isItYourInnV2' },

    { path: '/is-it-your-fio', component: () => import('@/pages/IsItYourFio'), name: 'is-it-your-fio' },

    { path: '/enter-your-inn', component: () => import('@/pages/EnterYourInn'), name: 'enter-your-inn' },

    { path: '/enter-your-inn-v2', component: () => import('@/pages/EnterYourInnV2/Index'), name: 'enter-your-inn-v2' },

    { path: '/inn-verification', component: () => import('@/pages/InnVerification'), name: 'innVerification' },

    { path: '/debug/main', component: () => import('@/pages/Debug/Main'), name: 'debugMain' },

    { path: '/background-inn-was-added-inform', component: () => import('@/pages/BackgroundInnWasAddedInform'), name: 'BackgroundInnWasAddedInform' },

    { path: '/checking-debts-by-inn-progress', component: () => import('@/pages/CheckingDebtsByInnProgress'), name: 'CheckingDebtsByInnProgress' },

    { path: '/b2b/limiting-screen', component: () => import('@/pages/B2B/LimitingScreen'), name: 'B2BLimitingScreen' },

    { path: '/contacts-form', component: () => import('@/pages/ContactsForm'), name: 'contactsForm' },

    { path: '/rnd/carmoney', component: () => import('@/pages/RND/Carmoney/index'), name: 'rndCarmoney', meta: { title: 'Получите до 1 000 000 ₽ онлайн за час' } },
    { path: '/rnd/licenses', component: () => import('@/pages/RND/licenses/index'), name: 'rndLicenses' },
    { path: '/rnd/service-station', component: () => import('@/pages/RND/ServiceStation/index'), name: 'rndServiceStation', meta: { title: 'Запишитесь на СТО онлайн' } },
    { path: '/rnd/camera-map', component: () => import('@/pages/RND/CameraMap/index'), name: 'rndCameraMap', meta: { title: 'Узнавайте о новых камерах в вашем городе'} },

    { path: '/rnd/carmoney/zaim/popup', component: () => import('@/pages/RND/ZaimPopup/index'), name: 'rndZaimPopup', meta: { title: 'Получите до 1 000 000 ₽ онлайн за час'}},
    { path: '/rnd/carmoney/zaim/landing', component: () => import('@/pages/RND/ZaimLanding/index'), name: 'rndZaimLanding', meta: { title: 'Получите до 1 000 000 ₽ онлайн за час'}},

    {path: '/:pathMatch(.*)*', redirect: 'start'}
  ]
});

export default router;
