import { createApp, nextTick } from 'vue';

import "vue-loading-overlay/dist/css/index.css";
import "@/assets/styles/style.scss";

import '@/utils/validate';

import App from './App';
import { store } from './store';
import router from './router';
import posthogPlugin from "./plugins/posthog";

const VueApp = window.VueApp = createApp(App);
VueApp.use(store);
VueApp.use(router);
VueApp.use(posthogPlugin);
VueApp.mount('#app');

router.afterEach((to, from, failure) => {
  document.title = to.meta.title ? to.meta.title : '-'
  if (!failure) {
    nextTick(() => {
      VueApp.config.globalProperties.$posthog.capture('$pageleave', { $current_url: window.location.host + from.fullPath, path: from.fullPath });
      VueApp.config.globalProperties.$posthog.capture('$pageview', { path: to.fullPath });
    });
  }
});

import VueFilters from '@rosfines/vue-common/vue/util/vue.filters';
VueApp.config.globalProperties.$filters = VueFilters;

if (process.env.NODE_ENV === 'production' && window.ym_whitelist_paths.includes(location.pathname)) {
  require('./assets/scripts/metrika');
}

// RND-only
if (location.pathname.includes('/rnd')) {
  require('./assets/scripts/rnd/metrika');
  require('./assets/scripts/rnd/google_tag_manager');
}
